import React from "react";

interface CookieProps {
  show: boolean;
  hidden: () => void;
}

export const CookieContext = React.createContext<CookieProps>({
  show: true,
  hidden: () => {},
});

const CookieContextProvider: React.FC = ({ children }) => {
  const [showCookie, setShowCookie] = React.useState<boolean>(true);
  return (
    <CookieContext.Provider
      value={{
        show: showCookie,
        hidden: () => setShowCookie(false),
      }}
    >
      {children}
    </CookieContext.Provider>
  );
};

export default CookieContextProvider;
