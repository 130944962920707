import React from "react";
import { WindowLocation } from "@reach/router"

interface AnalyticsContextType {
  uuid: string;
  referrer?: string;
  trackEvent: (category: string, action: string) => void;
}

interface AnalyticsContextProviderProps {
  location: WindowLocation;
}

export const AnalyticsContext = React.createContext<AnalyticsContextType>({
  uuid: "",
  trackEvent: () => undefined,
});

const sendData = (type: 'pageview' | 'event', data: { [key: string]: string | undefined }): void => {
  if (process.env.GATSBY_ANALYTICS_URL) {
    const formData = new FormData();
    Object.keys(data).forEach((key: string) => data[key] ? formData.append(key, (data[key] as string)) : undefined);
    try {
      fetch(`${process.env.GATSBY_ANALYTICS_URL}/${type}`, {
        method: "POST",
        cache: "no-cache",
        body: formData,
      })
      .catch(() => {});
    } catch (error) { }
  }
}

export const AnalyticsContextProvider: React.FC<AnalyticsContextProviderProps> = ({
  location,
  children,
}) => {
  const [referrer, setReferrer] = React.useState<string>();
  const [uuid, setUid] = React.useState<string>("");

  React.useEffect(() => {
    setUid(Date.now().toString(36) + Math.random().toString(36).substring(2));
  }, []);

  React.useEffect(() => {
    if (!referrer && document.referrer) {
      setReferrer(document.referrer);
    }
  }, [referrer]);

  React.useEffect(() => {
    if (location.pathname && uuid) {
      sendData('pageview', {
        "id": uuid,
        "path": location.pathname,
        "referrer": document.referrer,
      });
    }
  }, [location, uuid]);

  
  const trackEvent = (category: string, action: string) => {
    sendData('event', {
      "id": uuid,
      "category": category,
      "action": action,
    });
  };


  const context: AnalyticsContextType = {
    referrer,
    uuid,
    trackEvent,
  };

  return (
    <AnalyticsContext.Provider value={context}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = (): AnalyticsContextType => {
  const context = React.useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error('useAnalytics must be used within a AnalyticsContext')
  }
  return context;
};