import React from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyle, theme } from "./src/styles/styles";
import "./src/styles/global.css";
import { AnalyticsContextProvider } from "./src/components/analytics";
import CookieContextProvider from "./src/components/cookie/cookie-context";

export const wrapPageElement = ({ element, props }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <AnalyticsContextProvider location={props.location}>
      <CookieContextProvider>{element}</CookieContextProvider>
    </AnalyticsContextProvider>
  </ThemeProvider>
);
