const widths = {
  mobileDesign: 375,
  tablet: 768,
  desktopDesign: 1440,
};

const addPx = (obj) => {
  const newObj = {};
  for (const key in obj) {
    newObj[key] = obj[key] + "px";
  }
  return { ...newObj };
};

module.exports = {
  widths,
  content: ["./src/**/*.{js,ts,jsx,tsx}"],
  theme: {
    screens: addPx(widths),
    fontFamily: {
      default: ["MieleBeta49", "Arial", "sans-serif"],
    },
    extend: {
      colors: {
        gray: "#1F1F1F",
        gray2: "#2B2B2B",
        gray3: "#454545",
        gray4: "#6B6B6B",
        gray5: "#A6A6A6",
        gray6: "#4B4B4B",
        gray7: "#D0D0D0",
        gray8: "#C5C5C5",
        gray9: "#868686",
        gray10: "#252525",
        gray11: "#8E8E8E",
        gray12: "#4D4D4D",
        gray13: "#F6F6F6",
        gray14: "#393939",
        gray15: "#363636",
        gray16: "#BFBFBF",
        gray17: "#171717",
        gray18: "#333333",
        lightgray: "#9F9F9F",
        yellow: "#F59B00",
        bordergray: "#4E4E4E",
        bordergray2: "#555555",
        red: "#B7001A",
        red2: "#8C0014",
        red3: "#FC5A5A",
        green: "#97f500",
      },
      borderRadius: {
        "4xl": "2rem",
      },
      fontSize: {
        brandMobile: [
          "2rem",
          {
            lineHeight: "2.875rem",
          },
        ],
        paragraph: [
          "1.125rem",
          {
            lineHeight: "1.375rem",
          },
        ],
        paragraphTablet: [
          "1.625rem",
          {
            lineHeight: "2.125rem",
          },
        ],
        workHeadline: [
          "1.375rem",
          {
            lineHeight: "2.125rem",
          },
        ],
        subHeadline: [
          "5.5rem",
          {
            lineHeight: "5.25rem",
          },
        ],
        modalHeadline: [
          "3rem",
          {
            lineHeight: "3.25rem",
          },
        ],
        subHeadlineMobile: [
          "2.5rem",
          {
            lineHeight: "3rem",
          },
        ],
        factsDesktop: [
          "5.5rem",
          {
            lineHeight: "3rem",
          },
        ],
        benefit: [
          "1.125rem",
          {
            lineHeight: "1.25rem",
          },
        ],
        benefitMobile: [
          "1.3125rem",
          {
            lineHeight: "1.875rem",
          },
        ],
        menuHamburger: ["2rem"],
        "10xl": "10rem",
      },
      lineHeight: {
        17: "4.25rem",
      },
      backgroundImage: {
        "gradient-radial": "radial-gradient(var(--tw-gradient-stops))",
      },
      gridTemplateColumns: {
        14: "repeat(14, minmax(0, 1fr))",
      },
      gridTemplateRows: {
        '0fr': '0fr',
        '1fr': '1fr',
      },
      gridRowStart: {
        8: "8",
        9: "9",
        10: "10",
        11: "11",
        12: "12",
        13: "13",
        14: "14",
        15: "15",
        16: "16",
        17: "17",
        18: "18",
      },
      zIndex: {
        x: "1",
      },
      padding: {
        18: "4.5rem",
        22: "5.5rem",
        50: "12.5rem",
      },
      margin: {
        18: "4.5rem",
        30: "7.5rem",
      },
    },
  },
  plugins: [],
  darkMode: "media",
};
