import { DefaultTheme, createGlobalStyle, css } from "styled-components";
import { TailwindThemeValue, TailwindThemeGetter, TailwindConfig } from "tailwindcss/tailwind-config";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../tailwind.config";

const fullConfig = resolveConfig(tailwindConfig as TailwindConfig);

const { widths } = tailwindConfig;
const { colors, screens } = fullConfig.theme;

const getMediaQueries = (screens: TailwindThemeValue | undefined) => {
  interface MediaQueries {
    [key: string]: string;
  }
  const mediaQueries: TailwindThemeGetter<MediaQueries> = {};
  for (const key in screens) {
    mediaQueries[key as keyof typeof mediaQueries] = `(min-width: ${screens[key as keyof typeof screens]})`;
  }
  return mediaQueries;
};

export const theme: DefaultTheme = {
  fonts: {},
  colors: colors,
  mediaQueries: getMediaQueries(screens),
  widths,
};

export function rem(px: number | undefined): string|undefined {
  if (px === undefined) return;
  return String(px / 16) + "rem";
}

export const GlobalStyle = createGlobalStyle`
  /* :root {
    ${({ theme: { widths, mediaQueries } }) => css`
      font-size: ${(16 / widths.mobileDesign) * 100}vw;
      @media ${mediaQueries.tablet} {
        font-size: ${(16 / widths.desktopDesign) * 100}vw;
      }
     // @media ${mediaQueries.desktopDesign} {
     //   font-size: 16px;
     // }
    `}
  } */

/* covererd by @tailwind base - https://tailwindcss.com/docs/preflight */
/*
  body {
    padding: 0;
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
  } */
`;
